import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un mamelló central al capell. Aquest és bastant carnós de 3 a 8 cm de diàmetre que moltes vegades presenta el marge ondulat. Té una cutícula entapissada de fibril·les radials que es clivellen al mateix temps que el capell. És de colors variables, de groc palla a groc mel. Les làmines són primes, ventrudes, desiguals i juntes, de color groc pàl·lid a verdós passant a ocraci i l’aresta més blanca. El peu és cilíndric, engruixut a la base i cobert per fibril·les. Les espores són terroses, el·lipsoidals, de 9-18 x 5-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      